<template>
  <div>
    <b-row align-h="center">
      <b-col lg="6">
        <div class="card">
          <div class="card-body">
            <div class="mx-auto mb-3">
              <a routerLink="/">
                <img
                  v-if="brandLogo"
                  :src="brandLogo"
                  alt
                  height="35"
                />
                <img
                  v-else
                  src="@assets/images/logo.png"
                  alt
                  height="35"
                />
                <h6 class="text-muted text-logo">
                  {{ brandName}}
                </h6>
              </a>
            </div>

            <h6 class="h5 mb-0 mt-4">
              {{ $t('pages.forget_password.title') }}
            </h6>
            <p class="text-muted mt-1 mb-2">
              {{ $t('pages.forget_password.subtitle') }}
            </p>
            <b-button
              class="mb-3"
              variant="outline-danger"
              block
            >
              <i class="uil uil-mobile-android mr-2" />
              {{ $t('pages.forget_password.get_otp')}}
            </b-button>
            <b-form
              class="authentication-form"
              @submit.prevent="validateBeforeSubmit"
            >
              <div class="form-group">
                <label class="form-control-label">
                  {{ $t('pages.forget_password.otp_code') }}
                </label>
                <b-button
                  variant="link"
                  class="float-right text-muted text-unline-dashed ml-1 mb-1"
                >

                  {{ $t('pages.forget_password.resend')}}
                </b-button>
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <feather
                        type="lock"
                        class="align-middle icon-dual"
                      ></feather>
                    </span>
                  </div>
                  <b-form-input
                    id="code"
                    v-model="code"
                    type="text"
                    required
                  ></b-form-input>
                </div>
              </div>
              <b-form-group
                id="button-group"
                class="mt-4 mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('pages.forget_password.verify')}}
                </b-button>
              </b-form-group>
            </b-form>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              <router-link
                to="/login"
                class="text-primary font-weight-bold ml-1"
              >
                {{ $t('pages.forget_password.back_login')}}
              </router-link>
            </p>
          </div>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  page: {
    title: 'รีเซ็ตรหัสผ่าน',
  },
  data() {
    return {
      code: '',
      error: null,
      tryingToReset: false,
      isResetError: false,
      isSuccess: false,
      successMessage: null,
    }
  },
  computed: {
    ...mapGetters(['brandName', 'brandLogo']),
    placeholders() {
      return process.env.NODE_ENV === 'production'
        ? {}
        : {
            username: 'Use "admin" to log in with the mock API',
            password: 'Use "password" to log in with the mock API',
          }
    },
  },
  created() {},
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    validateBeforeSubmit() {
      this.tryingToReset = true
      // Reset the authError if it existed.
      this.error = null
    },
  },
}
</script>
